import { render, staticRenderFns } from "./SettingCuration.vue?vue&type=template&id=edea8422&scoped=true"
import script from "./SettingCuration.vue?vue&type=script&lang=js"
export * from "./SettingCuration.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/curation/settingcurationview.css?vue&type=style&index=0&id=edea8422&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edea8422",
  null
  
)

export default component.exports